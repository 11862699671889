import styled from "styled-components";

import Button from "../../../../common/components/Button/Button";
import { FormLinks } from "../../shared/styles/Form.styles";

export const GoogleSignUpButton = styled(Button)`
  margin: 3rem 0;

  img {
    margin-right: 0.5rem;
  }
`;

export const SignUpForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 3rem 0;
`;

export const SignUpFormLinks = styled(FormLinks)``;
